import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IApiGetLedgerTransactionParams, IApiLedgerItem } from 'services/api/transactions/types';
import { useDispatch, useSelector } from 'react-redux';
import { getLedgerTransactionsRequest } from 'redux/reducers/transactions/reducer';
import { getLedgerTransactions } from 'redux/reducers/transactions/selectors';
import { getDownloadKey } from 'redux/reducers/download/selectors';
import { getUserData } from 'redux/reducers/auth/selectors';
import { downloadKeyRequest, downloadStatementRequest } from 'redux/reducers/download/reducer';
import { IDownloadStatementParams } from 'redux/reducers/download/types';

import { useLocation } from 'react-router';
import Filters from '../Filters/Filters';
import TableHeader from '../TableHeader/TableHeader';
import TableBody from '../TableBody/TableBody';
import PerPageSelect from '../../../ui/PerPageSelect/PerPageSelect';
import Pagination from '../Pagination/Pagination';
import { navList } from '../../../routes/routesList';

export interface IStatementTableData {
	id: number;
	date: string;
	user_id: number | string;
	type: string;
	currency: {
		code: string;
		title: string;
	};
	debit: string;
	credit: string;
	fee: string;
	net: string;
	balance: string;
	description: string;
	reference: string;
	tx_id: string;
}

interface IStatementFilters {
	currency: string | undefined;
	type: 'deposit' | 'withdrawal' | 'trade' | undefined;
	tx_id: string | undefined;
	date_from: string | undefined;
	date_to: string | undefined;
	sort_column?: string;
	sort_direction?: string;
}

const StatementTable = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const currencyParam = searchParams.get('currency');
	const ledgerTransactions = useSelector(getLedgerTransactions);
	const key = useSelector(getDownloadKey);
	const user = useSelector(getUserData);
	const [fileTypeState, setFileTypeState] = useState('');
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const [currentPage, setCurrentPage] = useState(1);
	const pageCount = ledgerTransactions?.last_page ? ledgerTransactions?.last_page : 1;
	const [localFilter, setLocalFilter] = useState<any>({
		value: 'all',
		state: 0,
	});
	const [filters, setFilters] = useState<IStatementFilters>({
		date_from: undefined,
		date_to: undefined,
		currency: undefined,
		// currency: 'aud',
		type: undefined,
		tx_id: undefined,
	});

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	const handleGetFile = (fileType: string) => {
		dispatch(downloadKeyRequest());
		setFileTypeState(fileType);
	};

	useEffect(() => {
		if (currencyParam) {
			setFilters({
				...filters,
				currency: currencyParam,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyParam]);

	useEffect(() => {
		const params: IApiGetLedgerTransactionParams = {
			current_page: currentPage,
			per_page: perPage,
			...filters,
		};
		dispatch(getLedgerTransactionsRequest(params));
	}, [currentPage, perPage, filters, dispatch]);

	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setTimeout(() => {
			const params: IDownloadStatementParams = {
				userId: String(user?.id),
				fileType: fileTypeState,
				key,
			};
			dispatch(downloadStatementRequest(params));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					<p>Statement</p>
				</div>
				<div className="wallet-content-header__buttons flex flex-e flex-m">
					<Link to={navList.trade.path} className="btn btn-primary">
						Trade
					</Link>
					<Link to={navList.depositFiat.path} className="btn btn-primary btn-primary--blue300">
						Deposit
					</Link>
					<Link to={navList.withdrawFiat.path} className="btn btn-primary btn-primary--blue100">
						Withdraw
					</Link>
				</div>
			</div>

			<Filters
				filters={filters}
				onChange={setFilters}
				setCurrentPage={setCurrentPage}
				transactionType={filters.type}
				activeCurrencyParam={currencyParam || undefined}
			/>

			<div className="table-block table-block--mt-0 table-block--with-y-scroll">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--statement-history">
						<TableHeader localFilter={localFilter} />

						<div className="table-body">
							{ledgerTransactions?.data && ledgerTransactions.data.length ? (
								ledgerTransactions?.data.map((item: IApiLedgerItem, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<TableBody key={`${item.tx_id}-${index}`} data={item} />
								))
							) : (
								<div className="td text--center">Transactions is empty</div>
							)}
						</div>
					</div>
				</div>
				<div className="table-footer">
					<div className="select-page">
						<PerPageSelect onChange={handleSetPerPage} />
					</div>

					<div className="pagination-block">
						{ledgerTransactions?.data && ledgerTransactions?.data.length ? (
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						) : null}
					</div>

					<div className="button-wrap">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button onClick={() => handleGetFile('csv')} type="button" className="btn btn--csv" />
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button onClick={() => handleGetFile('xls')} type="button" className="btn btn--xlsx" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatementTable;
