import { ChangeEvent, FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getInvoiceFiles } from 'redux/reducers/transactions/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPopUp } from '../types';
import { getErrorMsg } from '../../../redux/reducers/errors/selectors';
import { setErrorMsg } from '../../../redux/reducers/errors/reducer';
import { IFiatDepositGetFile } from '../../../services/api/transactions/types';
import { postFiatDepositInvoiceCreateRequest } from '../../../redux/reducers/transactions/reducer';

export interface ITransactionProps {
	currencyCode: string;
	payloadData: IPayloadData;
	cleanFunc: any;
}

interface IPayloadData {
	asset_id: number;
	amount: number;
	fee: number;
	reference?: string | undefined;
	client_notes?: string | undefined;
	fileList?: File[];
	status: string;
}

const InvoiceGenerate: FC<IPopUp & ITransactionProps> = ({
	open,
	closeModal,
	currencyCode,
	payloadData,
	cleanFunc,
}) => {
	const dispatch = useDispatch();
	const invoiceFiles = useSelector(getInvoiceFiles);
	const invoiceData = useSelector(getInvoiceData);
	const errorMessage = useSelector(getErrorMsg);

	const [totpValid, setTotpValid] = useState(false);
	const [showCheckTotp, setShowCheckTotp] = useState(true);
	const [totp, setTotp] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (errorMessage && errorMessage === 'Invalid Authentication Code.') {
			setError('Your 2FA code is incorrect.');
		}
		return () => {
			dispatch(setErrorMsg(''));
		};
	}, [errorMessage, dispatch]);

	useEffect(() => {
		setShowCheckTotp(true);
		setTotpValid(false);
	}, []);

	const transactionSubmit = (obj: IFiatDepositGetFile) => {
		setLoading(true);
		const formData = new FormData();
		payloadData.fileList &&
			payloadData.fileList.length &&
			payloadData.fileList.forEach((file: string | Blob) => formData.append(`files[]`, file));

		Object.keys(obj).forEach((key) => {
			const value = obj[key as keyof typeof obj];
			if (value !== undefined) {
				formData.append(key, value.toString());
			}
		});
		dispatch(
			postFiatDepositInvoiceCreateRequest({
				body: formData,
				onFinally: (hasError: boolean) => {
					setLoading(false);
					if (!hasError) {
						setShowCheckTotp(false);
						setTotpValid(true);
					}
				},
			}),
		);
	};

	const handleTotpChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError('');
		dispatch(setErrorMsg(''));
		const { value } = e.target;
		if (value.length === 0) {
			setError('Please enter 2FA code.');
		}
		if (value.length > 6) {
			return;
		}
		if (/^[0-9]*$/.test(value)) {
			setTotp(value);
		}
	};

	const handleConfirm = () => {
		if (totp.length === 0) {
			setError('Please enter 2FA code.');
			return;
		}
		if (error) {
			return;
		}
		transactionSubmit({
			asset_id: payloadData.asset_id,
			amount: payloadData.amount,
			fee: payloadData.fee,
			reference: payloadData.reference,
			client_notes: payloadData.client_notes,
			totp,
			status: payloadData.status,
		});
		cleanFunc();
	};

	const closeModalHandler = () => {
		closeModal();
		setShowCheckTotp(true);
		setTotpValid(false);
		setTotp('');
	};

	return (
		<Popup open={open} onClose={closeModalHandler}>
			<div className="modal">
				<div className="popup popup--width-520">
					<div className="popup__close-btn" onClick={closeModalHandler} />

					{showCheckTotp && (
						<>
							<div className="popup-header popup-header--mb-20">
								<p className="popup-header__title">Security Verification</p>
							</div>
							<div className="popup-body">
								<div className="input">
									<p className="input__name">Authentication Code</p>
									<div className="input-wrapper">
										<input
											onChange={handleTotpChange}
											value={totp}
											className={`input-item ${error ? 'error-border' : ''}`}
											type="text"
											placeholder="Enter 2FA Code"
											disabled={loading}
										/>
										{error && (
											<div className="input__notification input__notification--type2 input__notification--ta-r">
												{error}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="popup-footer">
								<div className="popup-submit popup-submit--column">
									<button
										type="button"
										className="btn btn-primary btn--full"
										disabled={loading}
										onClick={handleConfirm}
									>
										Confirm
									</button>
									<button
										onClick={closeModalHandler}
										type="button"
										className="btn btn-danger btn--full"
									>
										Cancel
									</button>
								</div>
							</div>
						</>
					)}

					{totpValid && (
						<>
							<div className="popup-header">
								<p className="popup-header__title">Deposit Instructions</p>
							</div>
							<div className="popup-body">
								<div className="invoice">
									{/* <div className="invoice-header">
										<p>TRANSFER FUNDS TO BANK ACCOUNT BELOW</p>
									</div> */}
									<div className="invoice-list">
										<div className="invoice-list__item">
											<p>Deposit Amount:</p>
											<span>
												{roundingNumber(invoiceData?.amount)} {currencyCode}
											</span>
										</div>
										<div className="invoice-list__item">
											<p>Deposit Fee:</p>
											<span>
												{roundingNumber(invoiceData?.fee)} {currencyCode}
											</span>
										</div>
										<div className="invoice-list__item">
											<p>Currency:</p>
											<span>{currencyCode}</span>
										</div>
										{/* <div className="invoice-list__item">
											<p>Deposit Method:</p>
											<span>{invoiceData?.deposit_method}</span>
										</div> */}
										{/* <div className="invoice-list__item invoice-list__item--type2">
											<p>Account Holder:</p>
											<span>{invoiceData?.account_holder}</span>
										</div> */}
										<div className="invoice-list__line" />
										<div className="invoice-list__item">
											<p>Beneficiary Name:</p>
											<span>{invoiceData?.beneficiary_name}</span>
										</div>
										<div className="invoice-list__item">
											<p>Beneficiary Address:</p>
											<span>{invoiceData?.beneficiary_address}</span>
										</div>
										<div className="invoice-list__item">
											<p>Bank Name:</p>
											<span>{invoiceData?.bank_name}</span>
										</div>
										<div className="invoice-list__item">
											<p>Bank Address:</p>
											<span>{invoiceData?.bank_address}</span>
										</div>
										<div className="invoice-list__item">
											<p>Account Number:</p>
											<span>{invoiceData?.account_number}</span>
										</div>
										<div className="invoice-list__item">
											<p>SWIFT / BIC:</p>
											<span>{invoiceData?.swift}</span>
										</div>
										<div className="invoice-list__item">
											<p>Transfer Reference:</p>
											<span style={{ overflowWrap: 'break-word' }}>
												{invoiceData?.transfer_reference}
											</span>
										</div>
										<div className="invoice-list__item invoice-list__item--type2 invoice-list__item--mb-0">
											<p>
												Please ensure you include the reference exactly as it appears above with no
												extra text. Failure to use the correct reference may result in delays
												crediting your deposit.
											</p>
										</div>
										{/* <div className="invoice-list__item invoice-list__item--type2">
											<p>IMPORTANT:</p>
											<span>{invoiceData?.important}</span>
										</div> */}
									</div>
								</div>
							</div>
							<div className="popup-footer">
								<div className="popup-submit popup-submit--type2 mt-20">
									<a href={invoiceFiles?.url_download} className="btn btn-primary btn--full">
										Download Instructions
									</a>
									<button
										type="button"
										onClick={closeModalHandler}
										className="btn btn-danger btn--full"
									>
										Close
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</Popup>
	);
};

export default InvoiceGenerate;
