import { FC, useEffect, useState } from 'react';
import { IMessage } from 'redux/reducers/messages/types';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFilesRequest } from 'redux/reducers/messages/reducer';
import { getMessages } from 'redux/reducers/messages/selectors';
import ReactHtmlParser from 'react-html-parser';

export interface IAccordion {
	message: IMessage;
}

const Accordion: FC<IAccordion> = ({ message }) => {
	const dispatch = useDispatch();
	const [active, setActive] = useState(false);
	const data = useSelector(getMessages);

	useEffect(() => {
		setActive(false);
	}, [data?.current_page]);

	const handleDownloadFile = (url: string) => {
		dispatch(downloadFilesRequest({ file: url }));
	};

	return (
		<div className="accordion__item">
			<div
				className={`accordion__title${active ? ' is-active' : ''}`}
				onClick={() => setActive(!active)}
			>
				<p className="accordion__title-text">{message.title}</p>
				<div className="accordion__title-wrapper">
					<div className="accordion__title-date">
						<p>{moment(moment.utc(message.planned_on)).local().format('YYYY-MM-DD')}</p>
						<span>{moment(moment.utc(message.planned_on)).local().format('HH:mm:ss')}</span>
					</div>
					<span className="accordion__title-arrow" />
				</div>
			</div>
			{active && (
				<div className="accordion__content-text">
					{ReactHtmlParser(message.message)}
					{message.files && message.files.length ? (
						<div className="accordion__content-files">
							{message.files.map((item) => (
								<div key={item}>
									<a href={item} target="_blank" rel="noreferrer" download>
										{item}
									</a>
								</div>
							))}
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default Accordion;
